import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import Widget from "./Widgets";
import VisaoGeral from "./VisaoGeral";
import Section from "./Section";
import Faturamento from "./Faturamento";
import TotalClientes from './TotalClientes';


const Dashboard = () => {
  document.title = `Dashboard | ${process.env.REACT_APP_TITLE}`;

  const [rightColumn, setRightColumn] = useState(true);
  const toggleRightColumn = () => {
    setRightColumn(!rightColumn);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                {/* <Section rightClickBtn={toggleRightColumn} /> */}
                <Row>
                  <Widget />
                </Row>
                <Row>
                  <Col xl={12}>
                    <VisaoGeral />
                  </Col>
                </Row>
                <Row>
                  <Faturamento />
                  <TotalClientes />
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
