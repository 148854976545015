import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isPrincipal, setIsPrincipal] = useState(true);
  const [isConfiguracoes, setIsConfiguracoes] = useState(false);
  const [isUsuarios, setIsUsuarios] = useState(false);
  const [isClientes, setIsClientes] = useState(false);
  const [isOrdemServico, setIsOrdemServico] = useState(false);
  const [isIncidentes, setIsIncidentes] = useState(false);

  //Calender
  const [isFiliais, setFiliais] = useState(false);

  // Apps
  const [isItens, setItens] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Principal");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Principal") {
      setIsPrincipal(false);
    }
    if (iscurrentState !== "Configuracoes") {
      setIsConfiguracoes(false);
    }
    if (iscurrentState !== "Usuarios") {
      setIsUsuarios(false);
    }
    if (iscurrentState !== "Clientes") {
      setIsClientes(false);
    }
    if (iscurrentState !== "OrdemServico") {
      setIsOrdemServico(false);
    }
    if (iscurrentState !== "Incidentes") {
      setIsIncidentes(false);
    }
  }, [
    history,
    iscurrentState,
    isPrincipal,
    isConfiguracoes,
    isUsuarios,
    isClientes,
    isOrdemServico,
    isIncidentes,
  ]);

  const menuItems = [
    {
      label: "Principal",
      isHeader: true,
    },
    {
      id: "principal",
      label: "Principal",
      icon: "ri-home-line",
      link: "/principal",
      stateVariables: isPrincipal,
      click: function (e) {
        e.preventDefault();
        setIsPrincipal(!isPrincipal);
        setIscurrentState("Principal");
        updateIconSidebar(e);
      },
    },
    {
      id: "clientes",
      label: "Clientes",
      icon: "bx bx-user",
      link: "/clientes",
      stateVariables: isClientes,
      click: function (e) {
        e.preventDefault();
        setIsClientes(!isClientes);
        setIscurrentState("Clientes");
        updateIconSidebar(e);
      },
    },
    {
      id: "ordens-servico",
      label: "Ordens de Serviço",
      icon: "bx bx-wrench",
      link: "/ordens-servico",
      stateVariables: isOrdemServico,
      click: function (e) {
        e.preventDefault();
        setIsOrdemServico(!isOrdemServico);
        setIscurrentState("OrdemServico");
        updateIconSidebar(e);
      },
    },
    {
      id: "incidentes",
      label: "Incidentes",
      icon: "bx bx-error",
      link: "/incidentes",
      stateVariables: isIncidentes,
      click: function (e) {
        e.preventDefault();
        setIsIncidentes(!isIncidentes);
        setIscurrentState("Incidentes");
        updateIconSidebar(e);
      },
    },
    {
      label: "Administrativo",
      isHeader: true,
    },
    {
      id: "configuracoes",
      label: "Configurações",
      icon: "mdi mdi-application-cog",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsConfiguracoes(!isConfiguracoes);
        setIscurrentState("Configuracoes");
        updateIconSidebar(e);
      },
      stateVariables: isConfiguracoes,
      subItems: [
        {
          id: "fornecedores",
          label: "Fornecedores",
          link: "/fornecedores",
          parentId: "configuracoes",
        },
        {
          id: "servicos",
          label: "Serviços",
          link: "/servicos",
          parentId: "configuracoes",
        },
        {
          id: "planos",
          label: "Planos",
          link: "/planos",
          parentId: "configuracoes",
        },
        {
          id: "filiais",
          label: "Filiais",
          link: "/#",
          parentId: "configuracoes",
          isChildItem: true,
          click: function (e) {
            e.preventDefault();
            setFiliais(!isFiliais);
          },
          stateVariables: isFiliais,
          childItems: [
            {
              id: 1,
              label: "Filiais",
              link: "/filiais",
              parentId: "configuracoes",
            },
            {
              id: 2,
              label: "Segmentos",
              link: "/segmentos",
              parentId: "configuracoes",
            },
            {
              id: 3,
              label: "Expedientes",
              link: "/expedientes",
              parentId: "configuracoes",
            },
          ],
        },
        {
          id: "itens",
          label: "Itens",
          link: "/#",
          parentId: "configuracoes",
          isChildItem: true,
          click: function (e) {
            e.preventDefault();
            setItens(!isItens);
          },
          stateVariables: isItens,
          childItems: [
            {
              id: 1,
              label: "Itens",
              link: "/itens",
              parentId: "configuracoes",
            },
            {
              id: 2,
              label: "Categorias",
              link: "/categorias-itens",
              parentId: "configuracoes",
            },
            {
              id: 3,
              label: "Marcas",
              link: "/marcas-itens",
              parentId: "configuracoes",
            },
            {
              id: 4,
              label: "Medidas",
              link: "/medidas-itens",
              parentId: "configuracoes",
            },
          ],
        },
      ],
    },
    {
      id: "usuarios",
      label: "Usuários",
      icon: "bx bx-user-circle",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsUsuarios(!isUsuarios);
        setIscurrentState("Usuarios");
        updateIconSidebar(e);
      },
      stateVariables: isUsuarios,
      subItems: [
        {
          id: "usuario-cadastro",
          label: "Usuários",
          link: "/usuarios",
          parentId: "usuarios",
        },
        {
          id: "perfis",
          label: "Perfis",
          link: "/perfis",
          parentId: "usuarios",
        },
      ],
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
