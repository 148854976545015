import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";

const TotalClientes = () => {
  const [series, setSeries] = useState([
    {
      name: "Last Year",
      data: [
        253, 125, 202, 185, 404, 254, 158, 223, 192, 253, 125, 202,
      ],
    },
  ]);

  const dataColors = '["--vz-success", "--vz-light"]';

  var chartAudienceColumnChartsColors = getChartColorsArray(dataColors);
  var options = {
    chart: {
      type: "bar",
      height: 309,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20%",
        borderRadius: 6,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      fontWeight: 400,
      fontSize: "8px",
      offsetX: 0,
      offsetY: 0,
      markers: {
        width: 9,
        height: 9,
        radius: 4,
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    grid: {
      show: false,
    },
    colors: chartAudienceColumnChartsColors,
    xaxis: {
      categories: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: true,
        strokeDashArray: 1,
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis: {
      show: false,
    },
    fill: {
      opacity: 1,
    },
  };

  const onChangeChartPeriod = (pType) => {
    console.log(pType);
  };
  return (
    <React.Fragment>
      <Col xl={6}>
        <Card>
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Total Clientes</h4>
            <div className="d-flex gap-1">
              <button
                type="button"
                className="btn btn-soft-secondary btn-sm"
                onClick={() => {
                  onChangeChartPeriod("all");
                }}
              >
                Tudo
              </button>
              <button
                type="button"
                className="btn btn-soft-secondary btn-sm"
                onClick={() => {
                  onChangeChartPeriod("monthly");
                }}
              >
                1 mês
              </button>
              <button
                type="button"
                className="btn btn-soft-secondary btn-sm"
                onClick={() => {
                  onChangeChartPeriod("halfyearly");
                }}
              >
                6 meses
              </button>
              <button
                type="button"
                className="btn btn-soft-primary btn-sm"
                onClick={() => {
                  onChangeChartPeriod("yearly");
                }}
              >
                1 ano
              </button>
            </div>
          </CardHeader>
          <CardBody className="p-0 pb-2">
            <div>
              <ReactApexChart
                dir="ltr"
                options={options}
                series={series}
                type="bar"
                height="309"
                className="apex-charts"
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default TotalClientes;
